import { Routes as ReactRoutes } from 'react-router-dom';
import { Route } from 'react-router-dom';
/** Components */
import MainLayout from './features/Layout/MainLayout';
import DashboardIFRAME from 'features/Views/DashboardIFRAME';
import AgentPanelIFRAME from 'features/Views/AgentPanelIFRAME';
import ReportsIFRAME from 'features/Views/ReportsIFRAME';
import SystemIFRAME from 'features/Views/SystemIFRAME';

const Routes = () => {
  return (
    <MainLayout>
      <ReactRoutes>
        <Route
          path="/a/tickets/dashboard"
          caseSensitive
          element={<DashboardIFRAME />}
        />
        <Route
          path="/a/tickets/agentpanel"
          caseSensitive
          element={<AgentPanelIFRAME />}
        />
        <Route path="/a/tickets/reports" caseSensitive element={<ReportsIFRAME />} />
        <Route path="/a/tickets/system" caseSensitive element={<SystemIFRAME />} />
      </ReactRoutes>
    </MainLayout>
  );
};

export default Routes;
