// Types
import { INavIcon } from '../types/types';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InboxIcon from '@mui/icons-material/Inbox';
import { Assessment } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';

export const iconsData: INavIcon[] = [
  {
    title: 'global.dashboard',
    to: '/a/tickets/dashboard',
    icon: <DashboardIcon />,
  },
  {
    title: 'global.agentpanel',
    to: '/a/tickets/agentpanel',
    icon: <InboxIcon />,
  },
  {
    title: 'global.reports',
    to: '/a/tickets/reports',
    icon: <Assessment />,
  },
  {
    title: 'global.system',
    to: '/a/tickets/system',
    icon: <SettingsIcon />,
  },
];
